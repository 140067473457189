import React from "react"
import { navigate } from "gatsby"
import loadable from "@loadable/component"
import { Formiz, FormizStep, useForm } from "@formiz/core"
import SizeFix from "../utils/sizefix";
import { FormRadioData, FormInputData, FormInputTextData, FormConfig } from "../config/config-data"
import { FieldHidden } from "../utils/LeadFormUtils/fields";
const Step1 = loadable(() => import(/* webpackPrefetch: true */ "../utils/LeadFormUtils/steps/step1"))
const Step2 = loadable(() => import(/* webpackPrefetch: true */ "../utils/LeadFormUtils/steps/step2"))
const Step3 = loadable(() => import(/* webpackPrefetch: true */ "../utils/LeadFormUtils/steps/step3"))
const Step4 = loadable(() => import(/* webpackPrefetch: true */ "../utils/LeadFormUtils/steps/step4"))
import "../styles/leadform.css"



const categories = [ ...FormRadioData.map((v) => v.form_radio_category), ...FormInputData.map((v) => v.form_field_name), ...FormInputTextData.map((v) => v.form_field_name) ]

const LeadForm = ({ ThePage, NextPage, InitialPrice, SheetLink, SheetAuth, SheetID, RefID, FormSubject, NoTotalValueOption }) => {


  const [UniqueID] = React.useState(
    Date.now()
    + '-' +
    new Date().getUTCHours()
    + '-' +
    new Date().getUTCMinutes()
    + '-' +
    new Date().getUTCSeconds()
    + '-' +
    (Math.random().toString(36)+'00000000000000000').slice(2, 7)
    + '-' +
    new Date().getHours()
    + '-' +
    new Date().getMinutes()
    + '-' +
    new Date().getSeconds()
    + '-' +
    (Math.random().toString(36)+'00000000000000000').slice(2, 7)
  )



  const myRef = React.useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView() 



  const [realPassword, setRealPassword] = React.useState('')

  const handlePasswordChange = event => {
    setRealPassword(event.target.value);
  };



  const LeadForm = useForm();

  const InternalTotalValue = categories.reduce((total, c) =>
    total + (LeadForm.values[c]?.split("----")[1]
    ?
    parseInt(LeadForm.values[c]?.split("----")[1], 10)
    :
    0),
  InitialPrice
  ?
  InitialPrice
  :
  0);


  const TotalValue = `${InternalTotalValue}`

  const handleSubmit = (values) => {
    const data = { ...values, UniqueID, TotalValue, ThePage, RefID }
    if (realPassword.trim().length !== 0) {
      // Spam collection endpoint
      {FormConfig.form_spam_email &&
        fetch(FormConfig.form_spam_email, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(data)
        })
      }
    } else {
      if (FormConfig.form_firebase_link) {
        if (NextPage === "/giveaway-last/") {
          // Giveaway form endpoint
          fetch(`${FormConfig.form_firebase_link}giveaway/${UniqueID}.json?auth=${FormConfig.form_firebase_auth}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            body: JSON.stringify(data)
          })
        } else {
          // Purchase form endpoint
          fetch(`${FormConfig.form_firebase_link}purchase/${UniqueID}.json?auth=${FormConfig.form_firebase_auth}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            body: JSON.stringify(data)
          })
        }
      } else {
        if (SheetLink) {
          // Collect data to sheet using props
          fetch(SheetLink, {
            method: "POST",
            headers: {
              "Authorization": SheetAuth,
              "X-Spreadsheet-Id": SheetID,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
          })
          .then(r => r.json())
        } else {
          if (FormConfig.form_sheet_link) {
            // Collect data to sheet using config
            {FormConfig.form_sheet_link &&
              fetch(FormConfig.form_sheet_link, {
                method: "POST",
                headers: {
                  "Authorization": FormConfig.form_authorization,
                  "X-Spreadsheet-Id": FormConfig.form_x_s_s_id,
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
              })
              .then(r => r.json())
            }
          } else {
            {FormConfig.form_email &&
              // Collect data to email using props
              fetch(FormConfig.form_email, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json"
                },
                body: JSON.stringify(data)
              })
            }
          }
        }
      }
    }
  }



  // const PreviousData = { ...LeadForm.values, TotalValue, ThePage, NextPage, RefID }

  const getBackBtn = () =>
    !LeadForm.isFirstStep && (
      <button 
        type="button"
        id="leadform_1662795673795"
        className="Button_Class_Remover"
        onClick={() => {LeadForm.prevStep();executeScroll();}}
      >
        &larr; Back
      </button>
    );

  const getSubmitBtn = () =>
    LeadForm.isLastStep ? (
      <button
        type="submit"
        id="leadform_1662795675459"
        className="Button_Class_Remover"
        disabled={!LeadForm.isValid && LeadForm.isStepSubmitted}
        onClick={() => {
          LeadForm.isValid && navigate((NextPage || "/thankyou/"), {state: { UniqueID }}) // CHANGE THE FORM SUBMISSION REDIRECT PAGE
        }}
      >
        Submit &rarr;
      </button>
    ) : null;

  const getContinueBtn = () =>
    !LeadForm.isLastStep ? (
      <button
        type="submit"
        id="leadform_1662795677571"
        className="Button_Class_Remover"
        disabled={!LeadForm.isStepValid && LeadForm.isStepSubmitted}
        onClick={executeScroll}
      >
        Proceed &rarr;
      </button>
    ) : null;

  const showErrorMessage = () =>
    !LeadForm.isStepValid && LeadForm.isStepSubmitted && (
      <>
        <hr />
        <div id="leadform_1662795679739" className="Normal_Text">Oops! Looks like you missed some info, please scroll above!</div>
        <hr />
      </>
    );

  return (
    <Formiz connect={LeadForm} onValidSubmit={handleSubmit}>
      <SizeFix />
      <form noValidate onSubmit={LeadForm.submitStep} autoComplete={FormConfig.form_autocomplete_on ? "on" : "off"}>
        <div ref={myRef} />
        {!NoTotalValueOption && FormConfig.form_total_value_title &&
        <div className="Single_Line_Text_Card Total_Price">
          {FormConfig.form_total_value_title}: <span style={{color: "var(--Price)"}}>{`${FormConfig.form_currency_sign}${FormConfig.form_total_value_formatted ? Intl.NumberFormat('en', {notation: 'compact'}).format(InternalTotalValue) : InternalTotalValue}`}</span>
        </div>
        }
        {FormConfig.form_step_one &&
        <FormizStep name="step1">
          <Step1
            form={LeadForm}
          />
        </FormizStep>
        }
        {FormConfig.form_step_two &&
        <FormizStep name="step2">
          <Step2
            form={LeadForm}
          />
        </FormizStep>
        }
        {FormConfig.form_step_three &&
        <FormizStep name="step3">
          <Step3
            form={LeadForm}
          />
        </FormizStep>
        }
        {FormConfig.form_step_four &&
        <FormizStep name="step4">
          <Step4
            form={LeadForm}
          />
        </FormizStep>
        }
        {NextPage === "/giveaway-last/" &&
        <FieldHidden
          id="Giveaway Conclusion"
          name="Giveaway Conclusion"
          defaultValue="Undecided"
        />
        }
        {!FormConfig.form_firebase_link || !SheetLink || !FormConfig.form_sheet_link && FormConfig.form_email_subject &&
        <FieldHidden
          id="Form Subject"
          name="_subject"
          defaultValue={FormSubject || FormConfig.form_email_subject}
        />
        }
        <label className="Real_Password" htmlFor="Password">Password:</label>
        <input
          id="Password"
          name="Password"
          type="password"
          className="Real_Password"
          onChange={handlePasswordChange}
          autoComplete="new-password"
        />
        <div>
          <div style={{marginTop:"20px", color:"var(--Warning)"}}>
            {showErrorMessage()}
          </div>
          <div style={{margin:"20px 0"}} className="Flex_Container">
            {!FormConfig.form_hide_back_button && getBackBtn()}
            {getSubmitBtn()}
            {getContinueBtn()}
          </div>
        </div>
      </form>
    </Formiz>
  );
};

export default LeadForm;