import React from "react"
import { useField } from "@formiz/core"
import { FormConfig } from "../../../config/config-data"

const MyField = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    name
  } = useField(props);
  const { customID, label, type, price, hidePrice } = props;
  const showError = !isValid && !isFocused && (isSubmitted);

  const [isFocused, setIsFocused] = React.useState(false);
  const [customValue, setCustomValue] = React.useState('');

  const handleChange = (event) => {
    setCustomValue(event.target.value)
    setValue(event.target.value && price ? `${event.target.value} ---- ${price}` : event.target.value)
  }

  return (
    <div className="Form_Card">
      <div className={`${showError ? "is-error" : ""}`}>
        <label className="Text_Card" htmlFor={id}>
          <div className="Text_Card_Label">
            {label} {price && !hidePrice && FormConfig.form_currency_sign && <span style={{color:"var(--Price)"}}>({FormConfig.form_currency_sign}{FormConfig.form_price_formatted ? Intl.NumberFormat('en', {notation: 'compact'}).format(price) : price})</span>}:
          </div>
          {props.helptext &&
          <div className="Form_Help_Text">{props.helptext}</div>
          }
          <input
            key={resetKey}
            id={customID ? customID : id}
            name={name}
            type={type || "text"}
            value={customValue}
            className="Text_Input"
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            aria-invalid={!isValid}
            aria-describedby={!isValid ? `${id}-error` : null}
          />
        </label>
        {showError && errorMessage && (
        <span className="Form_Warning_Text" id={`${id}-error`}>
          {errorMessage}
        </span>
        )}
        {props.helpimage && (
        <details className="Collapse_Card">
          <summary className="Form_Help_Details Collapse_Title">
            Learn more about "{label}"
          </summary>
          <div>
            <img src={props.helpimage} width="100%" height="auto" alt="Learn More Helper"></img>
          </div>
        </details>
        )}
      </div>
    </div>
  );
};

export default MyField;