import React from "react"
import PropTypes from "prop-types"
import { useField } from "@formiz/core"
import { isRequired } from "@formiz/validations"
import { FormConfig } from "../../../config/config-data"

export const RadioGroupsField = ({ onChange = null, groups = [] }) => {
  return groups.map(
    ({ options, name, label, required, defaultValue, helpimage, helptext }, i) => {
      return (
        <div key={"simpleradiogroup-" + i} className="Form_Card">
          <div key={`${name}-${i}`}>
            <div className="Radio_Group_Text">
              <label>{label || name}</label>
            </div>
            {helptext &&
            <div className="Form_Help_Text">{helptext}</div>
            }
            <div>
              <RadiosField
                options={options}
                name={name}
                onChange={(value) => (onChange ? onChange(name, value) : null)}
                defaultValue={defaultValue}
                required={required}
                validations={[
                  {
                    rule: isRequired(),
                    message: 'Warning: Choose At Least One Option',
                  },
                ]}
              />
            </div>
          </div>
          {helpimage &&
          <details className="Collapse_Card">
            <summary className="Form_Help_Details Collapse_Title">
              Learn more about "{name}"
            </summary>
            <div>
              <img src={helpimage} width="100%" height="auto" alt="Learn More Helper"></img>
            </div>
          </details>
          }
        </div>
      );
    }
  );
};

export const RadiosField = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value
  } = useField(props);
  const { required, options = [] } = props;
  const showError = !isValid && isSubmitted;

  return (
    <div
      className={`${showError ? "is-error" : ""}`}
      key={resetKey}
      id={id}
    >
      <div className="Radio_Group">
        {options.map((v, i) => {
          return (
            <CustomRadio
              key={`${v.id}-${i}`}
              {...v}
              isChecked={v.value === value}
              isValid={isValid}
              setValue={setValue}
              required={required}
            />
          );
        })}
      </div>
      {showError && (
      <span className="Form_Warning_Text" id={`${id}-error`}>
        {errorMessage}
      </span>
      )}
    </div>
  );
};

const CustomRadio = ({
  image,
  id,
  price,
  hidePrice,
  value,
  label,
  setValue,
  isChecked,
  isValid
}) => {
  return (
    <div>
      <label htmlFor={id}>
        <input
          type={"radio"}
          value={value}
          id={id}
          required
          checked={isChecked}
          className="Radio_Input"
          onChange={(e) => setValue(value)}
          aria-describedby={!isValid ? `${id}-error` : null}
        />
        <div className="Radio_Card">
          {image && (
            <>
              <div className="Radio_Card_Image_Shadow">
                <img
                  src={image}
                  alt="#"
                  onClick={e => setValue(value)}
                  onKeyDown={e => setValue(value)}
                  aria-hidden="true"
                  data-pin-no-hover="true" //This is for Pinterest
                  className="Radio_Card_Image"
                />
              </div>
            </>
          )}
          <div className="Radio_Card_Text">
            <div>{label}</div>
            {price && !hidePrice && FormConfig.form_currency_sign &&
            <div style={{color:"var(--Price)"}}>({FormConfig.form_currency_sign}{FormConfig.form_price_formatted ? Intl.NumberFormat('en', {notation: 'compact'}).format(price) : price})</div>
            }
          </div>
        </div>
      </label>
    </div>
  );
};

RadiosField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.node
      ]),
      image: PropTypes.string
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};
