import React from "react"
import { useField } from "@formiz/core"

const FieldHidden = (props) => {
const {id} = useField(props)

  return (
    <input
      type="hidden"
      id={id}
      name={props.name}
      value={props.defaultValue}
    />
  );
}

export default FieldHidden;